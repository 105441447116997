import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import settings from './settings'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        {
          path: 'inbox',
          name: 'inbox',
          component: () =>
            import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox')
        },
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users')
        },

        {
          path: 'users/:id',
          name: 'user',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            )
        },
        {
          path: 'content',
          name: 'content',
          component: () =>
            import(/* webpackChunkName: "content" */ '@/views/Home/Content')
        },
        settings,
        {
          path: '/events',
          name: 'events',
          component: () =>
            import(
              /* webpackChunkName: "events.events" */ '@/views/Home/Events'
            )
        },
        {
          path: 'events/:id',
          name: 'event.details',
          component: () =>
            import(
              /* webpackChunkName: "events.events" */ '@/views/Home/Events/EventDetails'
            )
        },
        {
          path: 'bookings',
          name: 'bookings',
          component: () =>
            import(
              /* webpackChunkName: "events.events" */ '@/views/Home/Bookings/Index.vue'
            )
        },
        {
          path: 'bookings/:id',
          name: 'bookings.details',
          component: () =>
            import(
              /* webpackChunkName: "events.events" */ '@/views/Home/Bookings/Details.vue'
            )
        },
        {
          path: '/providers',
          name: 'providers',
          component: () =>
            import(
              /* webpackChunkName: "events.events" */ '@/views/Home/Providers'
            )
        },
        {
          path: '/providers/:id',
          name: 'provider.details',
          redirect: '/providers/:id/info',
          component: () =>
            import(
              /* webpackChunkName: "events.events" */ '@/views/Home/Providers/Details'
            ),
          children: [
            {
              path: '/providers/:id/info',
              name: 'provider.details.info',
              component: () => import('@/views/Home/Providers/components/Info')
            },
            {
              path: '/providers/:id/photos',
              name: 'provider.details.photos',
              component: () =>
                import('@/views/Home/Providers/components/Photos')
            },
            {
              path: '/providers/:id/services',
              name: 'provider.details.services',
              component: () =>
                import('@/views/Home/Providers/components/Services')
            },
            {
              path: '/providers/:id/listings',
              name: 'provider.details.listings',
              component: () =>
                import('@/views/Home/Providers/components/Listings')
            }
          ]
        },
        {
          path: '/service-type',
          name: 'service-type',
          component: () =>
            import(
              /* webpackChunkName: "events.events" */ '@/views/Home/ServiceType'
            )
        }
      ]
    }
  ]
})

import Event from '@/models/Event'
import Api from '@/services/api'
import { each, find } from 'lodash'
import Form from '@/utils/form'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    eventDetails: null,
    photos: [],
    eventForm: new Form({
      name: ''
    }),
    photoFormData: null,
    isSuccess: false
  },

  mutations: {
    setEventList(state, events) {
      each(events, event => {
        const exist = find(state.list, { id: event.id })
        if (!exist) {
          state.list.push(new Event(event))
        }
      })
    },

    setEventListMeta(state, meta) {
      state.listMeta = meta
    },

    clearEventList(state) {
      state.list = []
    },

    setEventDetail(state, event) {
      if (event instanceof Event) {
        state.eventDetails = event
      } else {
        state.eventDetails = new Event(event)
      }
    },

    setEventPhotos(state, photos) {
      state.photos = photos
    },

    setPhotoFormData(state, formData) {
      state.photoFormData = formData
    },

    resetForm(state) {
      state.eventForm = new Form({ name: '' })
      state.photoFormData = null
    }
  },

  actions: {
    async getEvents({ commit }, params) {
      const query = Event.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const result = await query.params({ limit: 20 }).get()

      commit('setEventList', result.data)
      commit('setEventListMeta', result.meta)
    },

    async addEvent({ state, commit }, payload) {
      try {
        await Api.post('/admin/events', payload.formData)
        state.isSuccess = true
        commit('resetForm')
        commit('clearEventList')
      } catch (error) {
        state.isSuccess = false
        if (error.response.status === 422) {
          state.eventForm.$setErrors(error.response.data.errors)
        }
      }
    },

    async getEventDetail({ commit }, id) {
      const { data } = await Api.get(`admin/events/${id}`)
      commit('setEventDetail', data.data)
    },

    async updateEvent({ commit }, formData) {
      const { data } = await Api.put(`admin/events/${formData.id}`, formData)
      commit('setEventDetail', data.data)
    },

    async getEventPhotos({ commit }, eventId) {
      const { data } = await this.$api.get(`admin/events/${eventId}/photos`)
      commit('setEventPhotos', data.data)
    },

    async uploadEventPhotos({ commit }, payload) {
      try {
        const { data } = await Api.post(
          `admin/events/${payload.event_id}/photos`,
          payload.formData
        )
        return data.data
      } catch (e) {}
    },

    async deleteEventPhoto({ commit }, payload) {
      await Api.delete(`admin/events/photos/${payload.photo_id}`)
    }
  }
}

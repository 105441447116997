import Provider from '@/models/Provider'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    details: null
  },

  mutations: {
    setProviderList(state, providers) {
      each(providers, provider => {
        const exist = find(state.list, { id: provider.id })
        if (!exist) {
          state.list.push(new Provider(provider))
        }
      })
    },

    clearProviderList(state) {
      state.list = []
    },

    setProviderListMeta(state, meta) {
      state.listMeta = meta
    },

    setProviderDetails(state, provider) {
      if (provider instanceof Provider) {
        state.details = provider
      } else {
        state.details = new Provider(provider)
      }
    },
    clearProviderDetails(state) {
      state.details = null
    }
  },

  actions: {
    async getProviders({ commit }, params) {
      const query = Provider.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setProviderList', res.data)
      commit('setProviderListMeta', res.meta)
    },

    async getProviderDetails({ commit }, id) {
      const { data } = await Api.get(`admin/providers/${id}`)
      commit('setProviderDetails', data.data)
    }
  }
}

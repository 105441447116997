import Model from './Model'
// import Avatar from './Avatar'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Service extends Model {
  resource() {
    return 'services'
  }

  get categoriesText() {
    return this.categories.map(c => c.name).join(', ')
  }

  get addedDate() {
    return dayjs
      .utc(this.created_at)
      .local()
      .format('MMM DD, YYYY hh:mm A')
  }
}

import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
  apiKey: 'AIzaSyA55DwGog4T9X7ThLAIEZTBxH7xjLcvJyQ',
  authDomain: 'shindig-9e067.firebaseapp.com',
  projectId: 'shindig-9e067',
  storageBucket: 'shindig-9e067.appspot.com',
  messagingSenderId: '477542854511',
  appId: '1:477542854511:web:16c21776e04b87e552429a',
  measurementId: 'G-B6ZTGZTK5K'
}

const app = firebase.initializeApp(firebaseConfig, 'shindig')

export default firebase.messaging(app)

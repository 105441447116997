import Service from '@/models/Service'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    details: null
  },

  mutations: {
    setServiceList(state, services) {
      each(services, service => {
        const exist = find(state.list, { id: service.id })
        if (!exist) {
          state.list.push(new Service(service))
        }
      })
    },

    clearServiceList(state) {
      state.list = []
    },

    setServiceListMeta(state, meta) {
      state.listMeta = meta
    },

    setServiceDetails(state, service) {
      if (service instanceof Service) {
        state.details = service
      } else {
        state.details = new Service(service)
      }
    },
    clearServiceDetails(state) {
      state.details = null
    }
  },

  actions: {
    async getServices({ commit }, { provider, params }) {
      const query = provider
        .services()
        .page(params.page || 1)
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setServiceList', res.data)
      commit('setServiceListMeta', res.meta)
    },

    async getProviderDetails({ commit }, { id, providerId }) {
      const { data } = await Api.get(
        `admin/providers/${providerId}/services/${id}`
      )
      commit('setServiceDetails', data.data)
    }
  }
}

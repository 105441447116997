import Booking from '@/models/Booking'
import { each, find } from 'lodash'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    details: null
  },

  mutations: {
    setBookingList(state, booking) {
      each(booking, type => {
        const exist = find(state.list, { id: type.id })
        if (!exist) {
          state.list.push(new Booking(type))
        }
      })
    },

    setBookingListMeta(state, meta) {
      state.listMeta = meta
    },

    clearBookingList(state) {
      state.list = []
    },

    setBookingDetails(state, booking) {
      if (booking instanceof Booking) {
        state.details = booking
      } else {
        state.details = new Booking(booking)
      }
    },
    clearBookingDetails(state) {
      state.details = null
    }
  },

  actions: {
    async getBookings({ commit }, params) {
      const query = Booking.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.status) {
        query.where('status', params.status)
      }

      const result = await query.params({ limit: 20 }).get()

      commit('setBookingList', result.data)
      commit('setBookingListMeta', result.meta)
    },

    async getBookingDetails({ commit }, id) {
      const { data } = await Api.get(`admin/bookings/${id}`)
      commit('setBookingDetails', data.data)
    },

    async approveBooking({ commit }, id) {
      const { data } = await Api.post(`admin/bookings/${id}/booking`)
      commit('setBookingDetails', data.data)
    },

    async rejectBooking({ commit }, id) {
      const { data } = await Api.post(`admin/bookings/${id}/booking`)
      commit('setBookingDetails', data.data)
    }
  }
}

import Listing from '@/models/Service'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    details: null
  },

  mutations: {
    setListingList(state, listings) {
      each(listings, listing => {
        const exist = find(state.list, { id: listing.id })
        if (!exist) {
          state.list.push(new Listing(listing))
        }
      })
    },

    clearListingList(state) {
      state.list = []
    },

    setListingListMeta(state, meta) {
      state.listMeta = meta
    },

    setListingDetails(state, listing) {
      if (listing instanceof Listing) {
        state.details = listing
      } else {
        state.details = new Listing(listing)
      }
    },
    clearServiceDetails(state) {
      state.details = null
    }
  },

  actions: {
    async getListings({ commit }, { provider, params }) {
      const query = provider
        .listings()
        .page(params.page || 1)
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setListingList', res.data)
      commit('setListingListMeta', res.meta)
    },

    async getProviderDetails({ commit }, { id, providerId }) {
      const { data } = await Api.get(
        `admin/providers/${providerId}/listings/${id}`
      )
      commit('setListingDetails', data.data)
    }
  }
}

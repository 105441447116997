import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import notification from './modules/notification'
import report from './modules/report'
import versionControl from './modules/version-control'
import event from './modules/event'
import provider from './modules/provider'
import service from './modules/service'
import serviceType from './modules/service-type'
import listing from './modules/listing'
import booking from './modules/booking'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    report,
    versionControl,
    event,
    provider,
    service,
    serviceType,
    listing,
    booking
  },
  state: {
    controls: {
      showDrawer: true
    }
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    }
  }
})

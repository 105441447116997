import ServiceType from '@/models/ServiceType'
import Form from '@/utils/form'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    serviceTypeDetails: null,
    form: new Form({
      id: null,
      name: ''
    }),
    isSuccess: false
  },

  mutations: {
    setServiceTypeList(state, serviceType) {
      each(serviceType, type => {
        const exist = find(state.list, { id: type.id })
        if (!exist) {
          state.list.push(new ServiceType(type))
        }
      })
    },

    setServiceTypeListMeta(state, meta) {
      state.listMeta = meta
    },

    clearServiceTypeList(state) {
      state.list = []
    },

    setFormData(state, data) {
      state.form = new Form(data)
    },

    resetForm(state) {
      state.form = new Form({ id: null, name: '' })
      state.form.$clearErrors()
    }
  },

  actions: {
    async getServiceTypes({ commit }, params) {
      const query = ServiceType.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const result = await query.params({ limit: 20 }).get()

      commit('setServiceTypeList', result.data)
      commit('setServiceTypeListMeta', result.meta)
    },

    async addServiceType({ commit }, payload) {
      await Api.post('/admin/service-types', payload)
      commit('resetForm')
      commit('clearServiceTypeList')
    },

    async updateServiceType({ commit }, payload) {
      await Api.put(`/admin/service-types/${payload.id}`, payload)
      commit('resetForm')
      commit('clearServiceTypeList')
    },

    async deleteServiceType({ commit }, payload) {
      await Api.delete(`/admin/service-types/${payload.id}`)
      commit('resetForm')
      commit('clearServiceTypeList')
    }
  }
}
